$breakpoint-tablet: 800px;

.signup {
  background: #1a2038;

  .signup-card {
    max-width: 800px;
    //min-width: 768px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }

    
    @media (min-width: $breakpoint-tablet) {
      min-width: 768px;
    }
  }
}
