/* Add a black background color to the top navigation */
/* Style the links inside the navigation bar */
/* Change the color of links on hover */
/* Add a color to the active/current link */
/* 
CCT Overview panel
 */
/* Tables
================================== */
/* Dropdown
================================== */
/* 
CCT Checkbox style  
 */
/* Base for label styling */
/* checkbox aspect */
/* checked mark aspect */
/* checked mark aspect changes */
/* hover style just for information */
/* Organization Chart */

.wrapper {
  // display: flex;
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;
}

.wrap4tspan {
  display: none;
}

.panel,
.panel-box-CCT,
.panel-search, .panel-connections {
  position: absolute;
  top: 107px;
  left: 0px;
 // background-color: white;
  border: 0px;
  width: 100%;
 // max-width: 400px;
  // min-height: 700px;
  height: max-content;
  visibility: hidden;
  padding: 1em;
  margin-top: 10px;
  z-index: 2;
  //box-shadow: 0px 8px 16px rgba(0,0,0,0.25);
}


.panel-connections {
	.panel-header {
		margin-bottom: 1em !important;
	}

	.MuiTableBody-root {
		font-size: 14px;
	}
}

.panel-header {
  margin-bottom: 1em;
  overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // text-align: center;
  font-weight: bold;
  font-size: 25px;
  color: #4B4A4B;
}

.panel-header2, .panel-header3 {
  margin:5px 0px;
  // text-align: center;
  // font-weight: bold;
  font-size: 16px;
  //color: #4B4A4B;
  text-transform: capitalize;
}

.panel-purpose,
.panel-bio,
.purpose-panel-bio,
.panel-competence,
.panel-roles,
.panel-teams,
.panel-location,
.panel-contact {
  margin-bottom:1em;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.75px;
  color: #4B4A4B;
}

.panel-bio-value,
.panel-competence-value,
.panel-teams-value,
.panel-location-value,
.panel-roles-value{
  font-weight: normal;
  color: #4B4A4B;
}

.panel-purpose-value,
.panel-competence-value,
.panel-teams-value,
.panel-location-value,
.panel-roles-value {
  margin:5px 0px 20px;  
  font-weight: normal;
  color: #4B4A4B;
}

.panel-bio, .purpose-panel-bio {
  color: #4B4A4B;
}

.panel-contact-value {
  margin:5px 0px 3px;  
  font-weight: normal;
  
}

.panel-contact-value .email,
.panel-contact-value .phone{
  color:#009a3d;
}

.children-node > div {
  padding-left:15px;
  font-size:16px;
  color: #4B4A4B;
}

h1 {
  color: black;
}

#table {
  overflow-y: auto;
  height: 400px;
  margin-top: 10px;

  table {
    border-collapse: collapse;
    // border: 2px black solid;
    font: 16px 'Source Sans Pro', sans-serif;
    width: 100%;

    th {
      font-weight: normal;
      color: #4B4A4B;
    }
    td {
      border: 0px black solid;
      text-align: center;
      color: #4B4A4B;
    }
  }
}

.parent-node, 
.self-node {
  font-size:16px;
}

.Rtable {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0px;
}
.Rtable-cell {
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 4px;
  overflow: hidden;
  list-style: none;
  border: 1px solid black;
}
.Rtable--2cols {
  > .Rtable-cell-1 {
    width: 35%;
    display: flex;
    align-items: center;
  }
  > .Rtable-cell-2 {
    width: 65%;
    font-size:16px;
  }
}
.Rtable--3cols {
  > .Rtable-cell {
    width: 33.33%;
  }
}
.hide {
  max-height: 0 !important;
}
.dropdown {
  .title {
    margin: 0.3em 0.3em 0.3em 0.3em;
    width: 100%;
    .fa-angle-right {
      float: right;
      margin-right: 0.7em;
      transition: transform 0.3s;
    }
  }
  .menu {
    transition: max-height 0.5s ease-out;
    max-height: 50px;
    overflow: scroll;
    .option {
      margin: 0.3em 0.3em 0.3em 0.3em;
      margin-top: 0.3em;
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
}
.pointerCursor {
  &:hover {
    cursor: pointer;
  }
}
.rotate-90 {
  transform: rotate(90deg);
}
.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 9px;
}
.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

ul.breadcrumb {
  padding: 10px 16px 0px;
  list-style: none;
  display: flex;
  margin: 0px;
  li {
    display: inline;
    font-size: 18px;
    color: #000;
    text-decoration: none;
  }
  li + li {
    &:before {
      padding: 8px;
      color: black;
      content: "/\00a0";
    }
  }
}

#searchicon{
  background-color: transparent;
  position: absolute;
  right: 20px;
  top: 25px;
  
}

[type="checkbox"] {
  &:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  &:checked {
    position: absolute;
    left: -9999px;
  }
  &:not(:checked) + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25em;
      height: 1.25em;
      border: 2px solid #ccc;
      background: #fff;
      border-radius: 4px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    &:after {
      content: "\2713\0020";
      position: absolute;
      top: 0.15em;
      left: 0.22em;
      font-size: 1.3em;
      line-height: 0.8;
      color: #09ad7e;
      transition: all 0.2s;
      font-family: 'Source Sans Pro', sans-serif;
      opacity: 0;
      transform: scale(0);
    }
  }
  &:checked + label {
    position: relative;
    padding-left: 1.95em;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 1.25em;
      height: 1.25em;
      border: 2px solid #ccc;
      background: #fff;
      border-radius: 4px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    }
    &:after {
      content: "\2713\0020";
      position: absolute;
      top: 0.15em;
      left: 0.22em;
      font-size: 1.3em;
      line-height: 0.8;
      color: #09ad7e;
      transition: all 0.2s;
      font-family: 'Source Sans Pro', sans-serif;
      opacity: 1;
      transform: scale(1);
    }
  }
}
label {
  &:hover {
    &:before {
      border: 2px solid #4778d9 !important;
    }
  }
}
rect {
  fill: forestgreen;
  z-index: 10;
  stroke: black;
  stroke-width: 1;
  width: 100px;
  height: 60px;
}
.node {
  rect {
    fill: rgb(38, 138, 38);
    stroke: black;
    stroke-width: 1px;
  }
  text {
    font: 12px sans-serif;
  }
}

rect.node-name {
  fill: #fff;
  // stroke: navy;
  stroke-width: 1px;
  width:100px;
  height:30px;
}

.link {
  fill: none;
  stroke: black;
  stroke-width: 2px;
}
