.autocomplete {
  background: white;
  z-index: 1000;
  font: 14px/22px "-apple-system", BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  overflow: auto;
  box-sizing: border-box;
  border: 1px solid #222A45;
  * {
    font: inherit;
  }
  > div {
    padding: 0 4px;
    &:hover {
      &:not(.group) {
        background: forestgreen;
        cursor: pointer;
      }
    }
  }
  .group {
    background: #eee;
  }
  > div.selected {
    background: forestgreen;
    color: #ffffff;
    cursor: pointer;
  }
}
